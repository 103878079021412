import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors, fonts } from './../style-utilities/variables';

const StyledHeader2 = styled.h2`
  margin-bottom: 1.45rem;
  @media (min-width: ${breakpoints.mobileMax}) {
    font-size: 28px;
  }
`;

const StyledH2 = ({ content, font, textStyle, ...remProps }) => {
  return (
    <StyledHeader2
      style={{ textTransform: textStyle, fontFamily: font }}
      {...remProps}
    >
      {content}
    </StyledHeader2>
  );
};

export default StyledH2;
