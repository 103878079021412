import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import { breakpoints, fonts, colors } from "../../style-utilities/variables";
//style:
import styled, { consolidateStreamedStyles } from "styled-components";

//components
import ShareWidget from "../../components/ShareWidget";
import ContactBanner from "../../components/ContactBanner";
import CaseStudySideBar from "../../components/CaseStudies/SingleCaseStudy/CaseStudySideBar";
import CaseStudyMainText from "../../components/CaseStudies/SingleCaseStudy/CaseStudyMainText";
import NavBar from "../../components/NavBar";
import CaseStudyStats from "../../components/CaseStudies/SingleCaseStudy/CaseStudyStats";
import CaseStudyQuotation from "../../components/CaseStudies/SingleCaseStudy/CaseStudyQuotation";
import HeroBannerBig from "../../components/HeroBannerBig";
import Related3Grid from "../../components/Related/Related3Grid";
import SEO from "../../components/SEO";

// THE VIEW
const StyledCaseStudyPage = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  .moreCaseStudies {
    display: flex;
    flex-direction: column;
    margin: 0;
    background-color: ${colors.secondaryGray};
    padding: 0rem 2rem 2rem 2rem;
    border-top: 1px solid #cfd0d1;
    border-bottom: 1px solid #cfd0d1;

    & > h2 {
      font-size: 22px;
      margin: 2rem auto;
      @media (min-width: ${breakpoints.mobileMax}) {
        font-size: 28px;
      }
    }
    .image-box,
    .noImage-box {
      height: 50%;
      margin-bottom: 0;
    }
    .item-content {
      height: 50%;
      padding: 1rem;
      @media (min-width: ${breakpoints.tablet}) {
        padding: 1.875rem;
      }
      @media (min-width: ${breakpoints.desktop}) {
        padding: 1.875rem;
      }
      p {
        font-size: 0.875rem;
      }
    }
    #seeAllCaseStudies {
      margin: 30px auto 0rem auto;
      width: 18rem;
    }
  }

  .related-container {
    display: grid;
    background-color: ${colors.secondaryGray};
    padding: 2rem 3rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 1.5rem;
    @media (min-width: ${breakpoints.tablet}) {
      width: 100%;
      /* //padding: 2rem 1rem; */
    }
    @media (min-width: ${breakpoints.desktop}) {
      /* //max-width: 65rem; */
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1rem;
    }
  }
  .reach-out {
    margin: 0 auto;
    max-width: 1500px;
    padding: 2rem 1.875rem;
    & > div {
      margin: auto;
      max-width: 1440px;
    }
    @media (min-width: ${breakpoints.tablet}) {
      padding: 3rem 1.875rem;
    }
    @media (min-width: ${breakpoints.desktop}) {
      padding: 5rem 1.875rem;
    }
  }
`;

/* THE CONTENT THAT CHANGES THE MOST FROM MOB TO DESK: */
const StyledMidContent = styled.div`
  margin: 1rem auto;
  @media (min-width: ${breakpoints.desktop}) {
    display: grid;
    align-items: start;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-gap: 1em;
    grid-template-areas:
      "side case case case case social "
      "stats stats stats stats stats stats "
      "quote quote quote quote quote quote ";
    padding: 0 2.5rem;
  }
`;

/* // THE PAGE: */
class CaseStudyPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  mockData = {
    allWordpressWpVertical: {
      edges: [
        {
          node: {
            wordpress_id: 37,
            name: "Automotive",
            slug: "automotive"
          }
        },
        {
          node: {
            wordpress_id: 72,
            name: "Business Services",
            slug: "business-services"
          }
        },
        {
          node: {
            wordpress_id: 73,
            name: "Consumer Packaged Goods",
            slug: "consumer-packaged-goods"
          }
        },
        {
          node: {
            wordpress_id: 74,
            name: "Education",
            slug: "education"
          }
        },
        {
          node: {
            wordpress_id: 75,
            name: "Energy",
            slug: "energy"
          }
        },
        {
          node: {
            wordpress_id: 76,
            name: "Entertainment",
            slug: "entertainment"
          }
        },
        {
          node: {
            wordpress_id: 77,
            name: "Healthcare",
            slug: "healthcare"
          }
        },
        {
          node: {
            wordpress_id: 78,
            name: "Hospitality",
            slug: "hospitality"
          }
        },
        {
          node: {
            wordpress_id: 61,
            name: "Insurance",
            slug: "insurance"
          }
        },
        {
          node: {
            wordpress_id: 60,
            name: "Manufacturing",
            slug: "manufacturing"
          }
        },
        {
          node: {
            wordpress_id: 34,
            name: "Restaurants",
            slug: "restaurants"
          }
        },
        {
          node: {
            wordpress_id: 50,
            name: "Retail",
            slug: "retail"
          }
        },
        {
          node: {
            wordpress_id: 15,
            name: "Technology",
            slug: "technology"
          }
        },
        {
          node: {
            wordpress_id: 79,
            name: "Telecommunications",
            slug: "telecommunications"
          }
        },
        {
          node: {
            wordpress_id: 96,
            name: "Tourism",
            slug: "tourism"
          }
        }
      ]
    },
    allWordpressWpServices: {
      edges: [
        {
          node: {
            wordpress_id: 20,
            name: "CRM and Loyalty Marketing",
            slug: "crm-loyalty-marketing"
          }
        },
        {
          node: {
            wordpress_id: 21,
            name: "National and Local Media",
            slug: "national-local-media"
          }
        },
        {
          node: {
            wordpress_id: 19,
            name: "Through-Channel Marketing Automation",
            slug: "through-channel-marketing-automation"
          }
        }
      ]
    },
    allWordpressWpTechnology: {
      edges: [
        {
          node: {
            wordpress_id: 22,
            name: "Brand Protection",
            slug: "brand-protection"
          }
        },
        {
          node: {
            wordpress_id: 42,
            name: "Channel Engagement",
            slug: "channel-engagement"
          }
        },
        {
          node: {
            wordpress_id: 43,
            name: "Local Marketing Activation",
            slug: "local-marketing-activation"
          }
        },
        {
          node: {
            wordpress_id: 80,
            name: "Market Intelligence",
            slug: "market-intelligence"
          }
        },
        {
          node: {
            wordpress_id: 23,
            name: "Trade Fund Management",
            slug: "trade-fund-management"
          }
        }
      ]
    },
    allWordpressWpCaseStudy: {
      edges: [
        {
          node: {
            title:
              "No Yolks Stirs Up Brand Awareness With Strategic Facebook Campaign",
            slug: "no-yolks-case-study",
            type: "case_study",
            topic: [54, 53],
            services: [21],
            vertical: [73],
            technology: [],
            date: "April 22, 2019",
            better_featured_image: {
              source_url:
                "https://api.dev.ansira.io/wp-content/uploads/2019/04/SalmonAsparagusPotPie.jpg",
              alt_text: "No Yolks salmon and asparagus pot pie"
            },
            acf: {
              client: "No Yolks",
              client_logo: {
                source_url:
                  "https://api.dev.ansira.io/wp-content/uploads/2019/04/No-Yolks-logo.png"
              },
              excerpt:
                "Ansira helped No Yolks increase brand awareness and intent to purchase among its target demographic with a strategic Facebook ad campaign."
            }
          }
        },
        {
          node: {
            title:
              "Aruba Lures Lucrative Beach Seekers With Robust CRM Solution",
            slug: "aruba-tourism-authority-case-study",
            type: "case_study",
            topic: [41, 45],
            services: [20],
            vertical: [78],
            technology: [],
            date: "December 1, 2018",
            better_featured_image: {
              source_url:
                "https://api.dev.ansira.io/wp-content/uploads/2019/03/85830222_xl.jpg",
              alt_text: "Aruba beach resort in the Caribbean"
            },
            acf: {
              client: "Aruba Tourism Authority",
              client_logo: {
                source_url:
                  "https://api.dev.ansira.io/wp-content/uploads/2018/12/A_LOGO_RGB_POS.png"
              },
              excerpt:
                "Ansira helped Aruba Tourism Authority increase repeat visits and on-island spend with a comprehensive customer-relationship management (CRM) program."
            }
          }
        },
        {
          node: {
            title:
              "Honda Revs Up Local Marketing With New and Improved TCMA Solution",
            slug: "honda-case-study",
            type: "case_study",
            topic: [39, 27, 32],
            services: [19],
            vertical: [37],
            technology: [42, 43],
            date: "January 15, 2018",
            better_featured_image: {
              source_url:
                "https://api.dev.ansira.io/wp-content/uploads/2019/03/PSP19-004.jpg",
              alt_text: "Man loading gear into a Honda SUV"
            },
            acf: {
              client: "Honda",
              client_logo: {
                source_url:
                  "https://api.dev.ansira.io/wp-content/uploads/2019/02/honda-logo.png"
              },
              excerpt:
                "When Honda needed to improve local marketing capabilities for its dealer partners, the automotive brand tapped Ansira to overhaul its ad-builder portal. "
            }
          }
        },
        {
          node: {
            title:
              "Savvy Search Engine Marketing Drives $1.5 Billion in Sales for Domino’s",
            slug: "dominos-case-study",
            type: "case_study",
            topic: [82, 30],
            services: [21],
            vertical: [34],
            technology: [],
            date: "December 15, 2017",
            better_featured_image: {
              source_url:
                "https://api.dev.ansira.io/wp-content/uploads/2017/12/DOM_Mix-N-Match_0647_Hero.jpg",
              alt_text: "Domino's pizza, boneless chicken, salad, and brownies"
            },
            acf: {
              client: "Domino’s",
              client_logo: {
                source_url:
                  "https://api.dev.ansira.io/wp-content/uploads/2019/02/dominos-logo.png"
              },
              excerpt:
                "Ansira’s comprehensive search engine marketing (SEM) strategy put Domino’s at the top of search listings, thereby transforming the pizza chain’s e-commerce business."
            }
          }
        },
        {
          node: {
            title:
              "The Coca-Cola Company Converts the Most Leads With Revamped B2B Site",
            slug: "coca-cola-company-case-study",
            type: "case_study",
            topic: [44, 30],
            services: [20, 21],
            vertical: [73],
            technology: [],
            date: "May 2, 2017",
            better_featured_image: {
              source_url:
                "https://api.dev.ansira.io/wp-content/uploads/2017/05/SMALL_BASQUETS_44.jpg",
              alt_text: "Coca-Cola glass bottles set against a skyline"
            },
            acf: {
              client: "The Coca-Cola Company",
              client_logo: {
                source_url:
                  "https://api.dev.ansira.io/wp-content/uploads/2017/05/CocaCola.png"
              },
              excerpt:
                "The Coca-Cola Company is one of the world’s most recognized and beloved brands, known predominantly for its signature beverage, Coca-Cola. "
            }
          }
        },
        {
          node: {
            title:
              "Multibrand CRM Solution Makes Purina a Top Dog With Customers",
            slug: "purina-case-study",
            type: "case_study",
            topic: [41, 45],
            services: [20],
            vertical: [73],
            technology: [],
            date: "November 1, 2016",
            better_featured_image: {
              source_url:
                "https://api.dev.ansira.io/wp-content/uploads/2019/03/26746495_l.jpg",
              alt_text: "Bowl of dog food with dog waiting in background"
            },
            acf: {
              client: "Purina",
              client_logo: {
                source_url:
                  "https://api.dev.ansira.io/wp-content/uploads/2018/11/Purina_Logo_Layered_RGB-01.png"
              },
              excerpt:
                "With help from Ansira, Purina activated a best-in-class customer-relationship management solution to increase growth across its dog food and cat food brands."
            }
          }
        }
      ]
    }
  };

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
      Preview Mode -------------------------------------
      */
    if (params.get("preview")) {
      let id = params.get("id");
      let wpnonce = params.get("_wpnonce");
      let BASE_URL = `https://api.dev.ansira.io/wp-json/v1/preview/`;
      fetch(`${BASE_URL}${id}`, {
        mode: "cors",
        credentials: "include"
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson
            });
          }
        });
    }
    /*[]
      -------------------------------------------------
      */
  }

  render() {
    const post = this.state.previewPayload;

    let relatedPostItems = [];
    let services = this.mockData.allWordpressWpServices.edges;
    let technologies = this.mockData.allWordpressWpTechnology.edges;
    let posts = this.mockData.allWordpressWpCaseStudy;
    let verticals = this.mockData.allWordpressWpVertical.edges;
    let filteredVerticals = [],
      filteredServices = [],
      filteredTechnology = [];

    if (post) {
      post.title = post.post_title;
      post.content = post.post_content;
      post.services = [21];
      post.technology = [];
      post.topic = [54, 53];
      post.vertical = [73];
      post.better_featured_image = {
        source_url:
          "https://via.placeholder.com/1500?text=Featured+Image+Not+Previewable"
      };

      // Generate list of verticals for this post
      if (post.vertical) {
        post.vertical.forEach(function(item) {
          verticals.forEach(function(o) {
            if (item === o.node.wordpress_id) {
              filteredVerticals.push(o.node);
            }
          });
        });
      }

      // Generate list of services for this post
      post.services.forEach(function(item) {
        services.forEach(function(o) {
          if (item === o.node.wordpress_id) {
            filteredServices.push(o.node);
          }
        });
      });

      // Generate list of services for this post
      post.technology.forEach(function(item) {
        technologies.forEach(function(o) {
          if (item === o.node.wordpress_id) {
            filteredTechnology.push(o.node);
          }
        });
      });

      /* filter the posts to get rid of do not delete dummy and slug */
      let nodes = posts.edges;
      /* filter for those that are NOT the current case study: */
      let filteredPosts = nodes.slice(0, 5).filter(post => {
        return (
          post.node.slug !== post.slug && post.node.slug !== "do-not-delete"
        );
      });

      //matches this case study with eligible content based on topics, services, verticals, tech

      let removeDuplicates = arr => {
        const final = [];
        arr.map((e, i) => !final.includes(e) && final.push(e));
        return final;
      };

      let postList = filteredPosts.filter(function(e) {
        return e.node.slug !== post.slug;
      });

      postList.filter(e => {
        e.node.topic.filter(d => {
          post.topic.map((topic, i) => {
            if (topic === d) {
              relatedPostItems.push(e.node);
            }
          });
        });
      });

      postList.filter(e => {
        e.node.services.filter(d => {
          post.services.map((services, i) => {
            if (services === d) {
              relatedPostItems.push(e.node);
            }
          });
        });
      });

      postList.filter(e => {
        e.node.vertical.filter(d => {
          post.vertical.map((vertical, i) => {
            if (vertical === d) {
              relatedPostItems.push(e.node);
            }
          });
        });
      });

      postList.filter(e => {
        e.node.technology.filter(d => {
          post.technology.map((technology, i) => {
            if (technology === d) {
              relatedPostItems.push(e.node);
            }
          });
        });
      });

      postList.map((post, index) => {
        relatedPostItems.push(postList[index].node);
      });

      relatedPostItems = removeDuplicates(relatedPostItems);
    }

    return post ? (
      <StyledCaseStudyPage>
        <NavBar name="Case Studies" link="/results/case-studies" />
        <h2 className="title page-container">{post.title}</h2>
        <CircleSection
          id="featured"
          background={post.better_featured_image.source_url}
          client={post.acf.client}
          challenge={post.acf.challenge_copy}
          logo={post.acf.client_logo.source_url}
          client={post.acf.client}
          topics={filteredTopics}
          verticals={filteredVerticals}
          services={filteredServices}
          technology={filteredTechnology}
        />
        <div className="page-container">
          <CaseStudyStats
            stats={post.acf.results}
            image={post.acf.results_image.source_url}
          />
          <CaseStudyQuotation
            quote={post.acf.quote_copy}
            source={post.acf.quote_author}
            solution={post.acf.solution_copy}
          />
          <Related3Grid
            header="Featured Case Studies"
            items={relatedPostItems}
            readAll="See All Case Studies"
            readAllLink="results/case-studies/"
            gridCardType="minimal"
          />
          <ContactBanner
            headline={post.acf.contact_headline}
            cta={post.acf.contact_cta}
            url={post.acf.contact_url}
          />
        </div>
      </StyledCaseStudyPage>
    ) : null;
  }
}

export default CaseStudyPreview;
