import React from "react";
import styled from "styled-components";
import { colors, breakpoints } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";

const StyledHero = styled.div`
  position: relative;
  width: 500px;
  padding-top: 500px;
  border-radius: 100%;
  left: -79px;
  margin: 2em 0;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  object-fit: cover;

  @media (min-width: 400px) {
    left: auto;
    margin: 2em auto;
  }
  @media (min-width: ${breakpoints.tablet}) {
    width: 70%;
    padding-top: 70%;
    position: absolute;
    right: -80px;
  }
`;
const Hero = ({
  headline,
  sub,
  client,
  logo,
  background,
  bgcolor,
  textcolor,
  id,
  tag,
  button,
  children,
  noGradient,
  ...remainProps
}) => {
  const heroContainBG = {
    backgroundImage: "url(" + background + ")"
  };

  return (
    <StyledHero className="hero-container" id={id} style={heroContainBG}>
      {children}
    </StyledHero>
  );
};

export default Hero;
