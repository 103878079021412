import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import styled from 'styled-components';
import { breakpoints, fonts, colors } from '../../../style-utilities/variables';

import StyledSectionComponent from './../../StyledSectionComponent';

const StyledChallSol = styled.div`
  margin: 1rem;
  font-size: 16px;
  @media (min-width: ${breakpoints.tabletMax}) {
    grid-area: case;
  }
  h1 {
    font-size: 30px;
    font-weight: 700;
    @media (min-width: ${breakpoints.tablet}) {
      margin-bottom: 2rem;
    }
    @media (min-width: ${breakpoints.desktop}) {
      font-size: 38px;
      margin-bottom: 2rem;
    }
  }
  .challSolHdr {
    margin: 1rem 0 0.5rem 0;
    h2 {
      font-size: 22px;
      margin-bottom: 0.5rem;
    }
    @media (min-width: ${breakpoints.tablet}) {
      margin: 1rem 0 0.5rem 0;
    }
  }
  p {
    font-size: 16px;
    color: ${colors.primaryGray};
  }
`;

const CaseStudyMainText = props => {
  return (
    <StyledChallSol className="content">
      <h1>{props.title}</h1>
      <StyledSectionComponent
        title="Challenge"
        className="challSolHdr"
      >
        <p>{ReactHtmlParser(props.challenge)}</p>
      </StyledSectionComponent>
      <StyledSectionComponent
        title="Solution"
        className="challSolHdr"
      >
        <p>{ReactHtmlParser(props.solution)}</p>
      </StyledSectionComponent>
    </StyledChallSol>
  );
};

export default CaseStudyMainText;
