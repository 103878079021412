import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import styled from 'styled-components';
import StyledH2 from './StyledH2';
import { breakpoints, colors, fonts } from './../style-utilities/variables';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  white-space: pre-line;
  li {
    color: ${colors.primaryGray};
  }
`;

const StyledSectionComponent = ({
  title,
  content,
  bgColor,
  textColor,
  headerFont,
  className,
  textAlign,
  children,
  ...remProps
}) => {
  return (
    <StyledSection
      className={className}
      style={{
        backgroundColor: bgColor,
        color: textColor,
        textAlign: textAlign
      }}
      {...remProps}
    >
      <StyledH2 content={title} style={{ fontFamily: headerFont }} />
      {children ? children : ReactHTMLParser(content)}
    </StyledSection>
  );
};

export default StyledSectionComponent;
